<template>
  <v-app v-if="!loading">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" lg="6" class="d-flex align-center justify-center">
        <v-sheet width="600" class="poppins d-flex flex-column align-center justify-center">
          <v-card height="600">
            <!-- NOTE Title logo -->
            <v-card-title>
              <v-container class="container-main">
                <div class="d-flex mb-8 center-content">
                  <v-img
                    id="horizontal_logo"
                    max-width="80"
                    :src="customization.hasOwnProperty('login_logo') ? customization.login_logo : require('../assets/images/logo.png')"
                    v-on:error="delete customization.login_logo"
                    contain
                    transition="scale-transition"
                  />
                  <span style="font-size:20px; color: #1E3988;">Philippine Society of General Surgeons</span>
                </div>
              </v-container>
            </v-card-title>
            <!--End of title Logo  -->
            <alert
              :show="msg.show"
              :text="msg.text"
              :type="msg.type"
            />
            <!-- NOTE Login Form -->
            <v-card-text>
              <span class="f20 fw600">Welcome back!</span>
              <br>
              <span>Login to your account</span>
              <v-form ref="form" class="mb-5" @submit.prevent="login" :class="$vuetify.breakpoint.mobile ? 'pa-5' : ''">
                <v-text-field
                  outlined
                  dense
                  type="email"
                  autocomplete="off"
                  label="Email *"
                  v-model="form.email"
                  required
                  class="login-custom-field my-5 f14 roboto fw500 secondary-1--text"
                  hide-details="auto"
                  persistent-placeholder
                  :rules="[() => !!form.email || 'The email field is required.']"
                />
                <v-text-field
                  outlined
                  dense
                  :type="!show ? 'password' : 'text'"
                  v-model="form.password"
                  class="login-custom-field f14 roboto fw500 secondary-1--text"
                  :append-icon="!show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="show = !show"
                  hide-details="auto"
                  required
                  persistent-placeholder
                  label="Password *"
                  :rules="[() => !!form.password || 'The password field is required.']"
                />
                <div class="d-flex justify-space-between align-center my-5">
                  <router-link to="/forgot-password" class="text-decoration-none">
                    <div class="mt-1 mx-1 primary--text f12">
                      Forgot Password
                    </div>
                  </router-link>
                </div>
                <v-btn
                  large
                  block
                  color="primary"
                  style="border-radius: 10px"
                  type="submit"
                >
                  <v-progress-circular
                    v-if="logging"
                    indeterminate
                    width="5"
                    color="#fff"
                  />
                  <div class="" v-else>
                    LOG IN
                  </div>
                </v-btn>
                <br>
                <!-- <div class="poppins text-center secondary--text f12">
                  Not registered yet?
                  <router-link to="/signup" class="text-decoration-none">
                    <span class="navy--text">Sign up</span>
                  </router-link>
                </div> -->
              </v-form>
            </v-card-text>
            <!-- End of Login Form -->
          </v-card>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="6" class="hidden-md-and-down">
        <div style="width: 100%; height: 100vh; overflow: hidden;">
          <v-img
            src="../assets/images/login-banner.png"
            style="width: 100%; height: 100%; object-fit: contain;"
          >
          </v-img>
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AuthFooter from '../components/AuthFooter.vue'
export default {
  components: {
    AuthFooter
  },
  data: () => ({
    form: { email: '', password: '' },
    show: false,
    logging: false,
    loading: true,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    landing_path: `${process.env.VUE_APP_LANDING_DOMAIN}`,
    images: {},
    errs: {
      email: [], // Sample data for errs.email
      password: [], // Sample data for errs.password
      status: [] // Sample data for errs.status
    },
    msg: {
      show: false,
      text: '',
      type: ''
    }
  }),
  created() {
    this.loading = true
    this.getCustomizationAction().then(res => {
      this.images = res
      this.loading = false
    })
  },
  watch: {
    errorMessage(newValue) {
      if (newValue === 'No such email or password') {
        this.msg.show = true;
        this.msg.text = 'Wrong password';
        this.msg.type = 'error';
      } else if (newValue !== '') {
        this.msg.show = true;
        this.msg.text = newValue;
        this.msg.type = 'error';
      } else {
        this.msg.show = false;
        this.msg.text = '';
        this.msg.type = '';
      }
    }
  },
  methods: {
    ...mapMutations(['signInMutation', 'tenantMutation', 'customizationMutation']),
    ...mapActions(['getCustomizationAction', 'loginAction']),
    ...mapActions('usr', ['addToCart']),
    login() {
      this.errs = []
      if (this.$refs.form.validate()) {
        this.logging = true
        this.loginAction(this.form).then(res => {
          console.log(res.role, 'test')
          if (res.status === 'active') {
            this.signInMutation(res)
            if (res.role === 'USER') {
              if (!res.first_name || !res.last_name) {
                // Redirect to /account-settings/profile if first_name exists
                this.$router.push({ name: 'User Account Profile' })
              } else {
                if (localStorage.getItem('cart')) {
                  if (JSON.parse(localStorage.getItem('cart')).length > 0) {
                    let _cart = JSON.parse(localStorage.getItem('cart'))
                    let _course_ids = []
                    _cart.forEach(_item => {
                      _course_ids.push(_item.id)
                    })
                    this.addToCart({ course_id: _course_ids }).then(() => {
                      localStorage.removeItem('cart')
                      setTimeout(() => {
                        this.msg = {
                          show: true,
                          text: "Login Successful!",
                          type: "success"
                        }
                      }, 2000)
                    })
                  } else {
                    localStorage.removeItem('cart')
                    this.msg = {
                      show: true,
                      text: "Login Successful!",
                      type: "success"
                    }
                  }
                } else {
                  this.msg = {
                    show: true,
                    text: "Login Successful!",
                    type: "success"
                  }
                }
              }
            }
          } else {
            this.msg = {
              show: true,
              text: "Your organization has disabled this account. Please contact your administrator.",
              type: "error"
            }
          }
          this.logging = false
        }).catch(e => {
          this.errs = e
          this.logging = false
        })
      }
    }

  },
  computed: {
    ...mapState({
      customization: (state) => state.customization,
      errors: (state) => state.errors,
    }),
    errorMessage() {
      if (this.errs.email) {
        if (this.errs.email[0] === 'Invalid email or password. Please check your credentials and try again.') {
          return 'No such email or password'
        } else return this.errs.email[0]
      } else if (this.errs.password) {
        if (this.errs.password[0] === 'Invalid email or password. Please check your credentials and try again.') {
          return 'No such email or password'
        } else return this.errs.password[0]
      } else if (this.errs.status) {
        return this.errs.status[0]
      } else return ''
    },
    passwordLabel() {
      return this.form.password === '' ? 'Password *' : 'Password';
    }
  }
}
</script>
<style scoped>
.card-align {
  /* Set a fixed height for the card */
  height: 400px;
  /* Add any additional styling as needed */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  transition: box-shadow 0.3s ease; /* Smooth transition for the shadow effect */
}

.card-align:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Change shadow on hover */
}

/* Additional styling for the card title */
.card-align .headline {
  font-size: 20px;
  margin-bottom: 10px;
}

/* Additional styling for the list items */
.card-align v-list-item {
  margin-bottom: 10px;
}

/* Additional styling for the text inside list items */
.card-align v-list-item-action-text p {
  font-size: 18px;
}
</style>
